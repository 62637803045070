<template>
  <section class="mt-4">
    <header>
      <h2>{{ 3 | stepTitle }}</h2>
      <p>Le domande precedute da un asterisco sono obbligatorie.</p>
    </header>

    <Stepper class="mb-4" :step="3" />

    <Step3Form />

    <Stepper class="my-4" :step="3" />
  </section>
</template>

<script>

import Stepper from '@/views/components/Stepper.vue';
import Step3Form from '@/views/components/Steps/Step3Form.vue';
import surveyMixin from '@/mixins/surveyMixin.js';
import stepTitleFilter from '@/filters/stepTitleFilter.js';

export default {
  mixins: [surveyMixin],
  filters: {
    stepTitle: stepTitleFilter,
  },
  components: {
    Stepper,
    Step3Form,
  },
};

</script>

<style lang="scss">
</style>
